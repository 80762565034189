
	import * as Config from '@/Config';
	import * as Factory from '@/Factory';
	import * as Model from '@/Model';
	import PageBase from '@/Page/Base.vue';
	import { Component, Ref } from 'vue-property-decorator';
	import { Event } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * This controller triggers a `interactionAnswer` action which should
	 * handle the resolution of answers for the Interaction views.
	 *
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class PageConversation extends PageBase {
		/**
		 * @type Ref
		 */
		@Ref('conversationInteraction')
		public conversationInteraction!: any;

		/**
		 * @return Model.Interaction
		 */
		public get interactionModel(): Model.Interaction {
			const roundNumber: number = this.questionNumber - 1;
			const roundTag: string = `round-0${roundNumber}`;
			const interactionModels: Model.Interaction[] = Factory.Interaction.getByTag(roundTag);
			const interactionModel: Model.Interaction = interactionModels[~~(Math.random() * interactionModels.length)];

			return interactionModel;
		}

		/**
		 * This represents which part of the conversation we're at out of 5
		 *
		 * @return number
		 */
		public get questionNumber(): number {
			return parseInt(this.$route.params.questionNumber || '1');
		}

		/**
		 * @type boolean
		 */
		public showResponse: boolean = false;

		/**
		 * @return void
		 */
		@mounted
		public attachEvents(): void {
			Event.Bus.on('conversation:next', () => (this.showResponse = false));
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		public detachEvents(): void {
			Event.Bus.off('conversation:next');
		}

		/**
		 * @return void
		 */
		protected gotoNextQuestion(): void {
			const nextQuestion: number = this.questionNumber + 1;

			// Navigate
			this.$router.push({
				name: 'PageConversationItem',
				params: {
					questionNumber: nextQuestion.toString(),
				},
			});
		}

		/**
		 * @return boolean
		 */
		protected hasNextQuestion(): boolean {
			return this.questionNumber < Config.App.MAX_QUESTIONS;
		}

		/**
		 * @param IConversationInteractionAnswer e
		 * @return Promise<void>
		 */
		protected async Handle_OnInteractionAnswer(e: IConversationInteractionAnswer): Promise<void> {
			// Score the answer
			this.$store.dispatch('interactionAnswer', e);

			// Display the response
			this.showResponse = true;

			// Animate out
			await this.conversationInteraction.animateOut();

			// The ConversationCounter middleware handles routing
			this.gotoNextQuestion();
		}

		/**
		 * @param number value
		 * @return Promise<void>
		 */
		protected async Handle_OnInteractionAnswerModifier(value: number): Promise<void> {
			// Score the answer
			this.$store.dispatch('interactionAnswerModifier', value);

			// The ConversationCounter middleware handles routing
			this.gotoNextQuestion();
		}
	}
